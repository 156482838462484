import { DatePipe } from '@angular/common';
import {  Component,  OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../Services/notification.service';
import { TokenService } from '../../Services/token.service';
import { PremiumService } from '../premium.service';
import { PremiumModel } from '../premium_model';

@Component({
  selector: 'app-pending-results',
  templateUrl: './pending-results.component.html',
  styleUrls: ['./pending-results.component.scss']
})
export class PendingResultsComponent implements OnInit {

    @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    userData:any;
    checknewfancy: Subscription;
    Allimportedfancies: any = [];
    fancyList: PremiumModel[];
    options = [
    {
      status: 0,
      type:"ZGS"
    }];
    sports = [
      {
        sportbfid: 4,
        name:"Cricket"
      },
      {
        sportbfid: 1,
        name:"Soccer"
      },
      {
        sportbfid: 2,
        name:"Tennis"
      }
    ];
  
    sportsAdd = [
      {
        sportbfid: 4,
        name:"cricket"
      },
      {
        sportbfid: 1,
        name:"soccer"
      },
      {
        sportbfid: 2,
        name:"tennis"
      }
    ];
    selectedType: any = 0;
    selectedSport: any = 4;
    selectedSportToAdd:any="";
    loader = false;
    selectedevent:any="";
    selectedeventToAdd:any="";
    fancydata: any;
    AllfancyList: any;
    Oldimportedfancy: any=[];
    Settlesession: any;
    highlights: any;
    highlightsAdd: any;
    Search: any;
    selectfromdate;
    selecttodate;
    eventDate:any="";
    eventId:any="";
    
    constructor(private fb: FormBuilder,private allapi: PremiumService, private notifyService: NotificationService,private tokenService: TokenService) {
      $(document).on("focus", 'input[type="search"]', function () {
        var ele:any = $('input[type="search"]').val();
        var table = $("#fancytable").DataTable();
        table.search(ele, false, false, false).draw();
      });
    }
    
  
    ngOnInit(): void {
      // this.selectfromdate = new Date(new Date());
      // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));
      this.userData=JSON.parse(this.tokenService.getUserData());
      this.getFancyList();
      // console.log(this.userData);
      this.initDatatable();    
    }
  
  
    initDatatable() {
      // console.log('table')
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 500,
        processing: true,
        responsive: true,
        deferRender: true,
        order:[[0, 'desc']]
      };
    }
  
    getFancyList() {
      this.loader = true;
      console.log(this.selectedType);
      if(this.selectedType!=null){
        this.allapi.getPendingPremium(this.selectedType).subscribe(resp => {
          console.log(resp);
          if(this.selectedType == 1){
            this.fancyList = resp.events;
          }else{
            this.fancyList = resp;
          }
          this.loader = false;
          this.rerender();
        })
      }
    }
    
    fancyinfodata(data) {
      this.fancydata = data;
      this.allapi.openPremiumMarketsWithBets(this.fancydata.eventId,this.fancydata.marketId).subscribe((resp)=>{
        if (resp.status =="Success") {
          this.notifyService.success(resp.result);
        } else {
          this.notifyService.error(resp.result);
        }
      })
    }
  

    onchangeinput(d) {
      if (d != null) {
        var event = [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
      } else {
        event = "";
      }
      // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
        var table = $("#fancytable").DataTable();
        table.search(event, false, false, false).draw();
    }
  
    trackfancyid(index,fancy)
    {
      return fancy.Id;
    }
    trckbyeventid(index,high)
    {
      return high.gameId;
    }
    ngAfterViewInit() {
      this.dtTrigger.next();
    }
  
    ngOnDestroy() {
      this.dtTrigger.unsubscribe();
    }
  
    rerender() {
      this.selectfromdate = null;
      this.selecttodate = null;
      this.onchangeinput(null)
      if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }
    }
  
  }
