<div class="vertical-overlay-menu">
    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow">
        <div class="leftPane">
            <ul id="MainMenu" class="navigation navigation-main">
                  
               
                <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/premiumsettle']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Premium Mgmt</span></a>
                    </li>
                    <!-- <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/pendingPremium']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Pending Results</span></a>
                    </li> -->
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/pending-premium-result']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Pending Premium</span></a>
                    </li>
                </ng-container>

            </ul>
        </div>
    </div>
</div>