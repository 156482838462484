import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PremiumService {
  GETAPIURL= environment.GETAPIURL;
  POSTAPIURL = environment.POSTAPIURL;

  constructor(private http: HttpClient) { }

  AddSpBook(data): Observable<any> {
    return this.http.post(`${this.POSTAPIURL}/addSportBookMarket`, data);
  }
  SettleSportBook(data): Observable<any> {
    return this.http.post(`${this.POSTAPIURL}/settleSportBook`, data);
  }

  GetSportbookmarket(sportsId,status,eventId): Observable<any> {
    return this.http.get(`${this.GETAPIURL}/getSportbookmarket/${sportsId}/${status}/${eventId}`);
  }

  GeteventsbySports(sportsId): Observable<any> {
    return this.http.get(`${this.GETAPIURL}/geteventsbySports/${sportsId}`);
  }

  reversePremiumGame(marketId): Observable<any> {
    return this.http.post(`${this.POSTAPIURL}/reversePremiumGame/${marketId}`, {});
  }

  AddPremiumGamewithbet(eventId): Observable<any> {
    return this.http.post(`${this.POSTAPIURL}/AddPremiumGamewithbet/${eventId}`, {});
  }

  openPremiumMarketsWithBets(eventId,sbmarketid): Observable<any> {
    return this.http.get(`${this.POSTAPIURL}/openPremiumMarketsWithBets/${eventId}/${sbmarketid}`);
  }

  AddTKEvent(data): Observable<any> {
    return this.http.post(`${this.POSTAPIURL}/AddTKEvent`, data);
  }
  getPendingPremium(type): Observable<any> {
    // if(type == 1){
    //   return this.http.get(`http://43.205.158.76:7001/callback/premiumMarketsWithBets`);
    // }else if(type == 2){
    //   return this.http.get(`https://k7k.asia/betfair/premiumMarketsWithBets`);
    // }
    // else{
    //   return this.http.get(`https://api.magic365.live/api/sports/open-premium-markets-results`);
    // }
    return this.http.get(`https://k7k.asia/betfair/premiumMarketsWithBets`);
  }

  UpdategameId(eventId,sbmarketId){
    return this.http.get(`${this.POSTAPIURL}//updateGameId/${eventId}/${sbmarketId}`);
  }

  getPremiumEvent(){
    return this.http.get(`${this.POSTAPIURL}/getPremiumEvents`);
  }

  getPremiumEventsky(){
    return this.http.get(`${this.POSTAPIURL}/getPremiumEventsSky`);
  }

  parlayPremiumGames(){
    return this.http.get(`https://111111.info/pad=81/parlayPremiumGames`);
  }

}
