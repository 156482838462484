import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Services/auth.gaurd';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { PremiumComponent } from './premium/premium.component';
import { PendingResultsComponent } from './premium/pending-results/pending-results.component';
import { PendingpremiumComponent } from './premium/pendingpremium/pendingpremium.component';

const routes: Routes = [
  { path: "login", component: LoginComponent, data: { title: 'LOGIN' } },
  {
    path: "", component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: PremiumComponent, data: { title: 'Premium' } },
      { path: "home", component: PremiumComponent, data: { title: 'Home' } },
      { path: "pending-premium-result", component: PendingpremiumComponent, data: { title: 'Pending' } },
      { path: "premiumsettle", component: PremiumComponent, data: { title: 'Premium' } },
      { path: "premiumsettle/:eventType/:eventId", component: PremiumComponent, data: { title: 'Premium' } },
      { path: "pendingPremium", component: PendingResultsComponent, data: { title: 'Pending Results' } },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true , relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
