import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PremiumService } from '../premium.service';

@Component({
  selector: 'app-pendingpremium',
  templateUrl: './pendingpremium.component.html',
  styleUrls: ['./pendingpremium.component.scss']
})
export class PendingpremiumComponent implements OnInit {

  options = [
    {
      status: 0,
      type: "Premium VRNL"
    },
    {
      status: 1,
      type: "Premium Iframe"
    },
    {
      status: 2,
      type: "Premium Parlay"
    }];
    
    sportName:object={
      4:"cricket",
      2:"tennis",
      1:"soccer",
    }
  selectedType: any = 0;
  selectfromdate;
  selecttodate;
  fancyList;
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  constructor(private allapi: PremiumService) { }

  ngOnInit(): void {
    this.getPremiumEvent()
  }

  getPremiumEvent() {
    if (this.selectedType == 0) {
      this.allapi.getPremiumEvent().subscribe((resp: any) => {
        this.fancyList = resp.data
        // console.log(this.fancyList);
        this.rerender();

      })
    }else if(this.selectedType == 1){
      this.allapi.getPremiumEventsky().subscribe((resp: any) => {
        this.fancyList = resp.data
        // console.log(this.fancyList);
        this.rerender();
      })

    } else {
      this.allapi.parlayPremiumGames().subscribe((resp: any) => {
        resp.result.forEach(ele=>{
          ele.parentTypeId = ele.sportId;
          ele.sportsName = this.sportName[ele.sportId];
        })
        this.fancyList = resp.result
        // console.log(this.fancyList);
        this.rerender();
      })
    }
  }
  trackfancyid(index, fancy) {
    return fancy.eventId;
  }


  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth() + 1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
    var table = $("#fancytable").DataTable();
    table.search(event, false, false, false).draw();
  }

  rerender() {
    this.selectfromdate = null;
    this.selecttodate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
