<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        List
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getFancyList()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select [(ngModel)]="selectedType" (ngModelChange)="getFancyList()">
                    <ng-option *ngFor="let option of options" [value]="option.status">{{option.type}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row" style="display: none;">
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="From Date" class="form-control" bsDatepicker
                    [(ngModel)]="selectfromdate" autocomplete="off" readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="To Date" class="form-control" bsDatepicker [(ngModel)]="selecttodate"
                    autocomplete="off" readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
        </div>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Event ID</th>
                        <th>Eventname</th>
                        <th>Market ID</th>
                        <th>Market Name</th>
                        <th>Action </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fancy of fancyList;let index=index;trackBy:trackfancyid">
                        <td>{{fancy.eventId}}</td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.eventName}}<br><span
                                style="font-size: 10px">{{fancy.startTime | date:'YYYY-MM-dd T HH:MM:SS'}}</span></td>

                        <td style="text-align: center;font-weight: 700;">{{fancy.marketId}}</td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.marketName}}</td>
                        <td>
                            <a class="btn btn-sm" *ngIf="fancy.status!=0 && userData.usertype == 2"
                                class="btn btn-info btn-sm" data-toggle="modal" data-target="#Confirmreversmodal"
                                title="Reverse Bet fancy" (click)="fancyinfodata(fancy)"><span>update</span></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-card-content>
</mat-card>