import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from '../Services/notification.service';
import { TokenService } from '../Services/token.service';
import { PremiumService } from './premium.service';
import { PremiumModel } from './premium_model';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AddEventForm: FormGroup;
  AddFancyForm: FormGroup;
  fancyList: PremiumModel[];
  userData: any;
  checknewfancy: Subscription;
  Allimportedfancies: any = [];
  options = [
    {
      status: 0,
      type: "Active"
    },
    {
      status: 1,
      type: "Settled"
    },
    {
      status: 2,
      type: "Canceled"
    }];
  sports = [
    {
      sportbfid: 4,
      name: "Cricket"
    },
    {
      sportbfid: 1,
      name: "Soccer"
    },
    {
      sportbfid: 2,
      name: "Tennis"
    },
    {
      sportbfid: 52,
      name: "Kabaddi"
    }
  ];

  sportsAdd = [
    {
      sportbfid: 4,
      name: "cricket"
    },
    {
      sportbfid: 1,
      name: "soccer"
    },
    {
      sportbfid: 2,
      name: "tennis"
    },
    {
      sportbfid: 52,
      name: "Kabaddi"
    }
  ];
  selectedStatus: any = 0;
  selectedSport: any = 4;
  selectedSportToAdd: any = "";
  loader = false;
  selectedevent: any = "";
  selectedeventToAdd: any = "";
  fancydata: any;
  AllfancyList: any;
  Oldimportedfancy: any = [];
  Settlesession: any;
  highlights: any;
  highlightsAdd: any;
  Search: any;
  selectfromdate;
  selecttodate;
  eventDate: any = "";
  eventId: any = "";
  result: any
  eventTypeID: any;
  eventID: any

  constructor(private fb: FormBuilder, private allapi: PremiumService, private notifyService: NotificationService, private tokenService: TokenService, private route: ActivatedRoute) {
    $(document).on("focus", 'input[type="search"]', function () {
      var ele: any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
    this.route.params.subscribe((resp) => {
      if (resp.eventType) {
        this.selectedSport = +resp.eventType;
        this.selectedevent = +resp.eventId;
        this.getFancyList();
      }
    })
  }


  ngOnInit(): void {
    // this.selectfromdate = new Date(new Date());
    // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));
    this.userData = JSON.parse(this.tokenService.getUserData());
    // console.log(this.userData);
    this.initUserForm();
    this.getHighlights();
    this.initDatatable();
  }

  initUserForm() {
    this.AddEventForm = this.fb.group({
      sportName: ['', Validators.required],
      eventName: ['', Validators.required],
      eventId: ['', Validators.required],
    });
    this.eventDate = "";
    this.eventId = "";
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order: [[0, 'desc']]
    };
  }

  getFancyList(term?: string) {
    if (this.selectedevent == "") {
      this.notifyService.error("Please select event");
      return false
    }
    this.loader = true;
    //console.log(this.selectedevent);

    if (this.selectedSport != null && this.selectedStatus != null && this.selectedevent != "") {
      this.allapi.GetSportbookmarket(this.selectedSport, this.selectedStatus, this.selectedevent).subscribe(resp => {
        this.fancyList = resp.data;
        // console.log(this.fancyList);

        this.fancyList.forEach(element => {
          if (element.sbrunners != null) {
            element.sbrunners = element.sbrunners.toString().replace("/", "");
            element.sbrunners = JSON.parse(element.sbrunners.toString().replace("/", ""));
          }
          if (element.result == null) {
            element.result = "";
          }
        });
        this.loader = false;
        this.rerender();
      })
    }
  }

  resetForm() {
    this.initUserForm();
  }

  getHighlights() {
    if (this.selectedSport != null) {
      this.allapi.GeteventsbySports(this.selectedSport).subscribe(resp => {
        this.highlights = resp.data;
      })
    }
  }

  getHighlightsToAdd() {
    if (this.selectedSportToAdd != null && this.selectedSportToAdd != "") {
      this.allapi.GeteventsbySports(this.selectedSportToAdd).subscribe(resp => {
        this.highlightsAdd = resp.data;
      })
    }
  }

  addEvent() {
    // console.log(this.AddEventForm.value);
    if (this.AddEventForm.valid) {
      let sportType = this.sportsAdd.filter(x => x.sportbfid == this.AddEventForm.value.sportName)
      var data = {
        "eventname": this.AddEventForm.value.eventName,
        "eventdate": new Date(),
        "eventid": this.AddEventForm.value.eventId,
        "eventtype": sportType[0].sportbfid,
        "eventtypename": sportType[0].name
      }
      this.allapi.AddTKEvent(data).subscribe(resp => {
        if (resp.status == "Success") {
          $("[data-dismiss=modal]").trigger("click");
          this.notifyService.success(resp.message);
        } else {
          this.notifyService.error(resp.message);
        }
      })
    } else {
      this.notifyService.error("Invalid Input");
    }

  }

  SettleBook(fancy) {
    //console.log(fancy);

    if (fancy.result == null || fancy.result == "") {
      this.notifyService.error("Please Select Result");
      return false;
    }
    var body = {
      "sbid": fancy.sbid,
      "status": 1,
      "result": fancy.result.map((item) => { return item.selectionName }).join(','),
      "settledby": this.tokenService.getUsername(),
      "reversed": null,
      "selectionId": fancy.result.map((item) => { return item.selectionId }).join(',')
    }
    //  console.log(body);


    this.allapi.SettleSportBook(body).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  fancyinfodata(data) {
    this.fancydata = data;
    console.log(this.fancydata);
  }

  cancelfancy(fancy) {
    var data = {
      "sbid": fancy.sbid,
      "status": 2,
      "result": "-1",
      "settledby": this.tokenService.getUsername(),
      "reversed": null,
      "selectionId": null
    }

    this.allapi.SettleSportBook(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Canceled Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  reversefancy(fancy) {
    var body = {
      "sbid": fancy.sbid,
      "status": 0,
      "result": null,
      "settledby": fancy.settledby,
      "reversed": this.tokenService.getUsername(),
      "selectionId": null
    }
    //console.log(fancy.sbmarketid);
    //this.reversePremiumGame(fancy.sbmarketid);
    this.allapi.SettleSportBook(body).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Reversed Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  AddPremiumGame() {
    this.allapi.AddPremiumGamewithbet(this.selectedevent).subscribe(data => {
      if (data.status == "Success") {
        //this.getFancyList();
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  reversePremiumGame(fancy) {
    if (fancy.gameId == null || fancy.gameId == "") {
      this.notifyService.error("GameId not found");
      return false;
    }
    this.allapi.reversePremiumGame(fancy.gameId).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    })
  }


  updategame(fancy) {
    this.allapi.UpdategameId(fancy.eventId, fancy.sbmarketid).subscribe(data => {
      // console.log(data);
      this.result = data
      if (this.result.status == "Success") {
        this.getFancyList();
        this.notifyService.success(this.result.message);
      } else {
        this.notifyService.error(this.result.message);
      }

    })
  }

  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth() + 1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
    var table = $("#fancytable").DataTable();
    table.search(event, false, false, false).draw();
  }

  trackfancyid(index, fancy) {
    return fancy.Id;
  }
  trckbyeventid(index, high) {
    return high.gameId;
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    this.selectfromdate = null;
    this.selecttodate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
