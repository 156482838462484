<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        List
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getPremiumEvent()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select [(ngModel)]="selectedType" (ngModelChange)="getPremiumEvent()">
                    <ng-option *ngFor="let option of options" [value]="option.status">{{option.type}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row" style="display: none;">
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="From Date" class="form-control" bsDatepicker
                    [(ngModel)]="selectfromdate" autocomplete="off" readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="text" placeholder="To Date" class="form-control" bsDatepicker [(ngModel)]="selecttodate"
                    autocomplete="off" readonly="true"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
        </div>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th class="t-center">Sports Name</th>
                        <th class="t-center">Event Name</th>
                        <th class="t-center">Event ID</th>
                        <th class="t-center">Game ID</th>
                        <th class="t-center">Action</th>
                   
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fancy of fancyList;let index=index;trackBy:trackfancyid">
                        <td class="event_link" style="text-align: center;font-weight: 700;" >{{fancy.sportsName}}</td>
                        <td class="event_link" style="text-align: center;font-weight: 700;"  >{{fancy.eventName}}  <br> <span
                            style="font-size: 10px">{{fancy.startTime.split("T")[0]}}</span></td>
                        <td class="event_link" class="t-center event_link " style="text-align: center;font-weight: 700;" >{{fancy.eventId}}</td>
                        <td class="event_link" style="text-align: center;font-weight: 700;"  >{{fancy.gameId}}</td>
                        <td class="event_link" style="text-align: center;font-weight: 700;"  [routerLink]="['/premiumsettle',fancy.parentTypeId,fancy.eventId]"> <i style="padding-top: 10px;font-size: 17px;color: #3b3bff;" class="fa fa-external-link" aria-hidden="true"></i> </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-card-content>
</mat-card>