let hostname = window.origin;

export const environment = {
  production: true,
  // BASEURL1 : "http://167.86.102.80:8061/api", 
  BASEURL : "https://api1.premiummarket.live:14441/api", 
  GETAPIURL:"https://api1.premiummarket.live:14441/api",
  POSTAPIURL:"https://api1.premiummarket.live:14441/api"
};


if (hostname.indexOf('panel.premiummarket.live') > -1) {
  if (location.protocol === 'https:') {
    environment.BASEURL = "https://api1.premiummarket.live:14441/api"; 
  }else{
    environment.BASEURL = "http://13.234.78.183:8061/api"; 
  }
}  else if (hostname.indexOf('108786') > -1) {
  environment.BASEURL = "https://api1.premiummarket.live:14441/api"; 
}