import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Material Modules 
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from './Services/token.service';
import { TokenInterceptor } from './Services/token-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidenavService } from './Services/sidenav.service';
import { AllapiService } from './Services/allapi.service';
import { LoginComponent } from './login/login.component';
import { LoginService } from './Services/login.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { PremiumComponent } from './premium/premium.component';
import { DatePipe } from '@angular/common';
import { PendingResultsComponent } from './premium/pending-results/pending-results.component';
import { PendingpremiumComponent } from './premium/pendingpremium/pendingpremium.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    PremiumComponent,
    PendingResultsComponent,
    PendingpremiumComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatSnackBarModule,
    DataTablesModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSidenavModule,
    MatTabsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),

  ],
  providers: [
    CookieService,
    TokenService,
    SidenavService,
    LoginService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AllapiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
