import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor(private cookieService: CookieService) {}

  setToken(token) {
    this.cookieService.set("kanaka", token);
  }
  getToken() {
    return this.cookieService.get("kanaka");
  }
  removeToken() {
    this.cookieService.delete("kanaka");
  }

  setUsername(uname) {
    this.cookieService.set("Username", uname);
  }
  setuserdata(data){
    this.cookieService.set("Userdata", data);
  }
  getUserData() {
    return this.cookieService.get("Userdata");
  }
  getUsername() {
    return this.cookieService.get("Username");
  }
  removeUsername() {
    this.cookieService.delete("Username");
    this.cookieService.delete("Userdata");
  }
}
